.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .year-nav + div {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid #423f3f;
    border-radius: 8.5px;
    padding: 4.5px;
  }
  
  .bp3-button {
    min-width: 45px;
  }
  